import React, { useState } from "react";
import {
  SWAP_STATE_ACCOUNT,
  useSwapperInfo,
  OLD_COPE_MINT,
  NEW_COP_MINT,
} from "../utils/governance";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Button,
} from "@material-ui/core";
import Spin from "../components/Spin";
import { useWallet } from "../utils/wallet";
import { useConnection } from "../utils/connection";
import { notify } from "../utils/notifications";
import { admin, Numberu64 } from "@bonfida/cope";
import { sendTransaction } from "../utils/send";
import { Transaction } from "@solana/web3.js";
import { refreshAllCaches } from "../utils/fetch-loop";

const useStyles = makeStyles({
  h1: {
    fontSize: "max(2vw, 60px)",
    fontWeight: 400,
    color: "white",
    opacity: 0.8,
    marginTop: "5%",
  },
  h2: {
    fontSize: "max(3vw, 40px)",
    fontWeight: 400,
    color: "white",
    opacity: 0.8,
    marginTop: "2%",
    marginBottom: "2%",
  },
  button: {
    color: "white",
    background: "transparent",
    width: "auto",
    borderRadius: 5,
    height: "50px",
    border: "2px solid",
    borderColor: "#5C1864",
    fontSize: 20,
    padding: 20,
  },
  text: {
    color: "white",
    fontSize: 24,
    marginTop: "2%",
    marginBottom: "2%",
  },
  gridContainer: {
    marginTop: "5%",
  },
  inputAmount: {
    color: "white",
    fontWeight: 600,
  },
  amountLabel: {
    fontSize: 16,
    color: "white",
  },
});

const AdminPage = () => {
  const classes = useStyles();
  const { wallet, connected } = useWallet();
  const connection = useConnection();
  const [info] = useSwapperInfo(SWAP_STATE_ACCOUNT);
  const [loading, setLoading] = useState(false);
  const [mintToWithdraw, setMintToWithdraw] = useState<string | null>(
    OLD_COPE_MINT.toBase58()
  );
  const [amountToWithdraw, setAmountToWithdraw] = useState<string | null>(null);

  const onClickWithdraw = async () => {
    if (!connected) {
      return notify({
        message: "Connect your wallet",
      });
    }
    if (!amountToWithdraw) {
      return notify({
        message: "Invalid amount",
      });
    }
    const parsedAmount = parseFloat(amountToWithdraw);
    if (isNaN(parsedAmount) || parsedAmount <= 0) {
      return notify({
        message: "Invalid amount",
      });
    }
    try {
      setLoading(true);
      let copeAmount =
        mintToWithdraw === OLD_COPE_MINT.toBase58() ? parsedAmount : 0;
      let govAmount =
        mintToWithdraw === NEW_COP_MINT.toBase58() ? parsedAmount : 0;
      const instruction = await admin(
        connection,
        new Numberu64(copeAmount),
        new Numberu64(govAmount * Math.pow(10, 6)),
        SWAP_STATE_ACCOUNT,
        wallet.publicKey
      );
      await sendTransaction({
        connection,
        transaction: new Transaction().add(...instruction),
        wallet: wallet,
      });
    } catch (err) {
      console.warn(`Error ${err}`);
      return notify({
        message: `Error ${err}`,
        variant: "error",
      });
    } finally {
      setLoading(false);
      refreshAllCaches();
    }
  };

  return (
    <>
      <Typography className={classes.h2} variant="h2" align="center">
        Balances
      </Typography>
      <Grid container justify="center" spacing={5}>
        <Grid item>
          <Typography className={classes.text}>
            Old COPE Balance: {info?.amountCope}
          </Typography>
          <Typography className={classes.text}>
            Old COPE Address: {info?.copeAddress}
          </Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.text}>
            New COPE Balance: {info?.amountGov}
          </Typography>
          <Typography className={classes.text}>
            New COPE Address: {info?.govAddress}
          </Typography>
        </Grid>
      </Grid>
      <Typography className={classes.h2} variant="h2" align="center">
        Withdraw
      </Typography>
      <Grid container justify="center" spacing={5}>
        <Grid item style={{ marginTop: 8 }}>
          <FormControl>
            <InputLabel>Coin</InputLabel>
            <Select
              label="Coin"
              className={classes.text}
              value={mintToWithdraw}
              // @ts-ignore
              onChange={(e) => setMintToWithdraw(e.target.value)}
            >
              <MenuItem value={OLD_COPE_MINT.toBase58()}>Old COPE</MenuItem>
              <MenuItem value={NEW_COP_MINT.toBase58()}>New COPE</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl>
            <Typography className={classes.amountLabel} variant="body1">
              Amount to withdraw
            </Typography>
            <TextField
              helperText="Amount to withdraw"
              value={amountToWithdraw}
              InputProps={{
                className: classes.inputAmount,
              }}
              // @ts-ignore
              onChange={(e) => setAmountToWithdraw(e.target.value)}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container justify="center">
        <Button onClick={onClickWithdraw} className={classes.button}>
          {loading ? <Spin size={20} /> : "Withdraw"}
        </Button>
      </Grid>
    </>
  );
};

export default AdminPage;
