import { useAsyncData } from "./fetch-loop";
import { PublicKey } from "@solana/web3.js";
import { useConnection } from "./connection";
import tuple from "immutable-tuple";
import { useWallet } from "./wallet";
import { findAssociatedTokenAddress, Swapper, PROGRAM_ID } from "@bonfida/cope";

export const SWAP_STATE_ACCOUNT = new PublicKey(
  "5ieSNfS8AbQGvETsPQKbSeD29Ajgp5BS6ACL2PKnaysb"
);

export const OLD_COPE_MINT = new PublicKey(
  "3K6rftdAaQYMPunrtNRHgnK2UAtjm2JwyT2oCiTDouYE"
);

export const NEW_COP_MINT = new PublicKey(
  "8HGyAAB1yoM1ttS7pXjHMa3dukTFGQggnFFH3hJZgzQh"
);

export const useTokenBalance = (mint: PublicKey, owner?: PublicKey) => {
  const connection = useConnection();
  const { wallet, connected } = useWallet();
  const get = async () => {
    if (!connected && !owner) {
      return null;
    }
    const account = await findAssociatedTokenAddress(
      owner ? owner : wallet.publicKey,
      mint
    );
    const accountInfo = await connection.getParsedAccountInfo(account);
    // @ts-ignore
    return accountInfo.value?.data?.parsed?.info?.tokenAmount?.uiAmount;
  };
  return useAsyncData(get, tuple("useTokenBalance", connected));
};

export const useSwapperSigner = async (swapperStateAccount: PublicKey) => {
  const swapper = new Swapper(swapperStateAccount);
  const connection = useConnection();
  const get = async () => {
    await swapper.load(connection);
    if (!swapper.swapperHeader?.signer_nonce) {
      return null;
    }
    const swapperSigner = await PublicKey.createProgramAddress(
      [
        swapperStateAccount.toBuffer(),
        Uint8Array.from([swapper.swapperHeader?.signer_nonce]),
      ],
      PROGRAM_ID
    );
    return swapperSigner;
  };
  return useAsyncData(
    get,
    tuple("useSwapperSigner", swapperStateAccount.toBase58())
  );
};

export const useSwapperInfo = (swapperStateAccount: PublicKey) => {
  const swapper = new Swapper(swapperStateAccount);
  const connection = useConnection();
  const get = async () => {
    await swapper.load(connection);
    if (!swapper.swapperHeader?.signer_nonce) {
      return null;
    }
    const swapperSigner = await PublicKey.createProgramAddress(
      [
        swapperStateAccount.toBuffer(),
        Uint8Array.from([swapper.swapperHeader?.signer_nonce]),
      ],
      PROGRAM_ID
    );
    const accountCope = await findAssociatedTokenAddress(
      swapperSigner,
      swapper.swapperHeader.copeMint
    );
    const accountCopeInfo = await connection.getParsedAccountInfo(accountCope);

    const accountGov = await findAssociatedTokenAddress(
      swapperSigner,
      swapper.swapperHeader.govTokenMint
    );
    const accountGovInfo = await connection.getParsedAccountInfo(accountGov);

    return {
      amountCope:
        // @ts-ignore
        accountCopeInfo.value?.data?.parsed?.info?.tokenAmount?.uiAmount,
      amountGov:
        // @ts-ignore
        accountGovInfo.value?.data?.parsed?.info?.tokenAmount?.uiAmount,

      copeAddress: accountCope.toBase58(),
      govAddress: accountGov.toBase58(),
    };
  };
  return useAsyncData(
    get,
    tuple("useSwapperBalances", swapperStateAccount.toBase58())
  );
};
