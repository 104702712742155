import React, { useState } from "react";
import {
  Typography,
  Grid,
  Button,
  TextField,
  InputLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  SWAP_STATE_ACCOUNT,
  OLD_COPE_MINT,
  NEW_COP_MINT,
} from "../utils/governance";
import { useWallet } from "../utils/wallet";
import { useConnection } from "../utils/connection";
import { notify } from "../utils/notifications";
import { sendTransaction } from "../utils/send";
import { Transaction } from "@solana/web3.js";
import Spin from "../components/Spin";
import copeGif from "../assets/homepage/cope.gif";
import {
  swap,
  Numberu64,
  findAssociatedTokenAddress,
  createAssociatedTokenAccount,
} from "@bonfida/cope";
import { refreshAllCaches } from "../utils/fetch-loop";

const useStyles = makeStyles({
  h1: {
    fontSize: "max(2vw, 60px)",
    fontWeight: 400,
    color: "white",
    opacity: 0.8,
    marginTop: "5%",
  },
  h2: {
    fontSize: "max(3vw, 40px)",
    fontWeight: 400,
    color: "white",
    opacity: 0.8,
    marginTop: "2%",
    marginBottom: "2%",
  },
  button: {
    color: "white",
    background: "transparent",
    width: "auto",
    borderRadius: 5,
    height: "50px",
    border: "2px solid",
    borderColor: "#5C1864",
    fontSize: 20,
    padding: 20,
  },
  text: {
    color: "white",
    fontSize: 24,
    marginTop: "2%",
    marginBottom: "2%",
    opacity: 0.8,
  },
  img: {
    height: "50%",
  },
  gridItem: {
    marginRight: "3%",
    marginLeft: "3%",
  },
  gridContainer: {
    marginTop: "5%",
  },
  inputProps: {
    color: "white",
  },
});

const ClaimSection = () => {
  const classes = useStyles();
  const { connected, wallet, select } = useWallet();
  const [loading, setLoading] = useState(false);
  const connection = useConnection();
  const [amount, setAmount] = useState<number | null>(null);

  const onChangeAmount = (e) => {
    const parsed = parseFloat(e.target.value.trim());
    if (isNaN(parsed) || !isFinite(parsed) || parsed < 0) {
      return setAmount(0);
    }
    setAmount(parsed);
  };

  const onClick = async () => {
    if (!connected) {
      return notify({ message: "Connect your wallet" });
    }
    if (!amount || isNaN(amount) || !isFinite(amount) || amount < 0) {
      return notify({ message: "Invalid amount" });
    }
    try {
      setLoading(true);

      // Check claimer account

      const claimerOldCopeAccount = await findAssociatedTokenAddress(
        wallet.publicKey,
        OLD_COPE_MINT
      );

      const claimerCopeAccountInfo = await connection.getAccountInfo(
        claimerOldCopeAccount
      );
      if (!claimerCopeAccountInfo?.data) {
        throw new Error(
          "No COPE account - Please create an associated token account"
        );
      }

      const claimerNewCopeAccount = await findAssociatedTokenAddress(
        wallet.publicKey,
        NEW_COP_MINT
      );

      const claimerNewCopeAccountInfo = await connection.getAccountInfo(
        claimerNewCopeAccount
      );
      if (!claimerNewCopeAccountInfo?.data) {
        const instructionCreateGovAccount = await createAssociatedTokenAccount(
          wallet.publicKey,
          wallet.publicKey,
          NEW_COP_MINT
        );
        await sendTransaction({
          wallet: wallet,
          transaction: new Transaction().add(instructionCreateGovAccount),
          connection: connection,
        });
      }

      const instruction = await swap(
        connection,
        new Numberu64(amount),
        SWAP_STATE_ACCOUNT,
        wallet.publicKey
      );

      await sendTransaction({
        wallet: wallet,
        transaction: new Transaction().add(...instruction),
        connection: connection,
      });
    } catch (err) {
      console.warn(`Error ${err}`);
      return notify({ message: `Error ${err}`, variant: "error" });
    } finally {
      setLoading(false);
      refreshAllCaches();
    }
  };

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      direction="column"
      spacing={5}
    >
      <Grid item>
        <Typography align="center" className={classes.h2}>
          1 xCOPE = 1 COPE
        </Typography>
      </Grid>

      <Grid item>
        <InputLabel>Amount</InputLabel>
        <TextField
          InputProps={{
            classes: {
              input: classes.inputProps,
            },
          }}
          value={amount}
          onChange={onChangeAmount}
        />
      </Grid>
      <Grid item>
        <Button
          disabled={loading}
          onClick={connected ? onClick : select}
          className={classes.button}
        >
          {loading ? <Spin size={20} /> : "Swap"}
        </Button>
      </Grid>
    </Grid>
  );
};

const ClaimPage = () => {
  const classes = useStyles();
  return (
    <>
      <Typography align="center" className={classes.h1} variant="h1">
        Swap your old COPE
      </Typography>
      <Typography align="center" className={classes.text} variant="body1">
        Old COPE (xCOPE) can be swapped 1:1 for new COPE
      </Typography>
      <Grid container justify="center">
        <img src={copeGif} alt="" className={classes.img} />
      </Grid>
      <ClaimSection />
    </>
  );
};

export default ClaimPage;
